import styled from "styled-components";

export const MainText = styled.div`
  flex: 1 0 auto;

  @media only screen and (min-width: 700px) {
    margin-right: 30px;
    max-width: 500px;
  }
`;

export const MainTitle = styled.h1`
  margin: 0 0 30px 0;

  @media only screen and (max-width: 700px) {
    font-size: 58px;
    font-weight: bolder;
  }
`;

export const MainSubtitle = styled.div`
  font-size: 20px;
  color: #4f5a79;
  line-height: 31px;

  @media only screen and (max-width: 700px) {
    padding: 0 20px;
  }

  @media only screen and (min-width: 700px) {
    max-width: 320px;
  }
`;

export const CenteredMainTitle = styled(MainTitle)`
  text-align: center;

  ${props => props.whiten && 'color: #FFFFFF'}
`;

export const CenteredMainDescription = styled.div`
  font-size: 20px;
  line-height: 31px;
  text-align: center;

  ${props => props.whiten && 'color: #FFFFFF'}
`;
