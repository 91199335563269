import "circular-std";
import styled from 'styled-components';

export const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 50px;
  column-gap: 20px;
  justify-items: center;
  align-items: center;
`;

export const SectionHeader = styled.h2`
  margin-top: 10px;
`;

export const SectionDescription = styled.div`
  font-size: 16px;
  color: #4F5A79;
  line-height: 30px;
  margin-bottom: 20px;

  ${props => props.whiten && 'color: white;'}
`;

export const AssetImage = styled.img`
  max-height: 100%;
  max-width: 100%;
`;

export const PlainPageTitle = styled.div`
  font-size: 45px;
  margin-bottom: 20px;
`;

export const PlainPageHeader = styled.div`
  font-size: 24px;
  margin: 10px 0;
`;

export const CaseStudyHeading = styled.h3`
  font-family: CircularStd;
  font-weight: 700;
  font-size: 49px;
  line-height: 59px;
  letter-spacing: -2.064px;
  color: #6673EE;
  text-align: left;
  margin: 50px 0 30px;
  @media only screen and (max-width: 767px) {
    margin: 30px 0 10px;
    font-weight: 600;
  }
`;

export const PlainPageMetadata = styled.div`
  margin: 0 0 50px 0;
`;