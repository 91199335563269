import styled from "styled-components";

const Container = styled.div`
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1100px;

  @media only screen and (max-width: 700px) {
    margin-bottom: 10px;
  }

  @media only screen and (min-width: 700px) {
    margin-bottom: 70px;
  }
`;

export default Container;
