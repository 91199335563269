import { css } from 'styled-components';

const baseButton = css`
  border-radius: 28px;
  padding: 15px 25px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  text-shadow: 0 1px 2px rgba(0,0,0,0.10);
  text-decoration: none;
  display: inline-block;

  &:hover {
    cursor: pointer;
    transform: translateY(-1px);
  }
`;

export const baseWhiteButton = css`
  ${baseButton}

  background: #FFFFFF;
  color: #7D8DF5;
  box-shadow: 0 20px 70px 0 rgba(24,32,72,0.18);
`;


export const basePurpleButton = css`
  ${baseButton}

  background-image: linear-gradient(-98deg, #9396FF 12%, #3E73D7 100%);
  color: #FFFFFF;
  box-shadow: 0 20px 70px 0 rgba(118,138,241,0.43);
`;

export const baseBoxButton = css`
  display: inline-block;
  font-size: 14px;
  letter-spacing: 0.13px;
  border-radius: 5px;
  text-decoration: none;
  padding: 15px 20px;
  margin-top: 15px;
  color: #637BE6;

  &:hover {
    background: #637BE6;
    color: #FFFFFF;
  }
`;
