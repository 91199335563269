import React from "react";
import PropTypes from "prop-types";
import styled, { css } from 'styled-components';

import FooterHills from "../../assets/footer/FooterHills.svg";
import { Link } from "gatsby";
import { baseWhiteButton } from "../button";
import { LogoLink, StyledWhiteLogo } from "../header/header";

const FooterContent = styled.footer`
  width: 100%;
  background-color: ${props => props.backgroundColor};
  background-image: ${props => props.backgroundImg ? props.backgroundImg : `url(${FooterHills})`};
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-size: cover;
  color: white;

  @media only screen and (max-width: 700px) {
    ${props => props.hideDemo ? css`
        padding: 0px;
      ` : css`
        padding: 300px 0 0 0;
      `}
  }

  @media only screen and (min-width: 700px) {
    ${
  props => props.hideDemo ?
    css`
        padding: 0px;
      `
    :
    css`
        padding: 400px 0 0 0;
      `
  }
  }
`;

const FooterRequestDemo = styled.div`
  text-align: center;

  @media only screen and (max-width: 700px) {
    padding: 0 20px 50px;
  }

  @media only screen and (min-width: 700px) {
    padding: 0 10px 200px 0;
  }
`;

const FooterRequestDemoTitle = styled.div`
  font-weight: 500;
  font-size: 54px;
  margin-bottom: 20px;
`;

const FooterRequestDemoSubtitle = styled.div`
  font-weight: 600;
  opacity: 0.7;
  font-size: 18px;
`;

const FooterRequestDemoLink = styled(Link)`
  ${baseWhiteButton}

  margin-top: 40px;
`;

const FooterBottom = styled.div`
  display: flex;
  border-top: 1px solid rgba(255,255,255,0.18);

  @media only screen and (max-width: 700px) {
    padding: 20px;
  }

  @media only screen and (min-width: 700px) {
    padding: 40px 50px 80px 50px;
  }
`;

const FooterLogoContainer = styled.div`
  padding: 30px 0;
  flex: 0 0 200px;
  display: flex;

  @media only screen and (max-width: 700px) {
    display: none;
  }
`;

const FooterLinks = styled.div`
  flex: 1 0 0;
  grid-row-gap: 30px;
  grid-column-gap: 20px;

  @media only screen and (max-width: 800px) {
    padding: 10px 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (min-width: 800px) {
    padding: 30px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  @media only screen and (min-width: 1200px) {
    padding: 30px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }
`;

const FooterLinkHeader = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 40px;
`;

const FooterLinkBase = css`
  opacity: 0.7;
  font-weight: 500;
  font-size: 16px;
  line-height: 40px;
  text-decoration: none;
  color: #FFFFFF;
  display: block;
`;

const FooterLinkExternal = styled.a`
  ${FooterLinkBase}
`;

const FooterLinkInternal = styled(Link)`
  ${FooterLinkBase}
`;

const FooterLinkText = styled.div`
  ${FooterLinkBase}
`;

const FooterLinkEmail = styled.div`
  margin-top: 5px;
  font-size: 20px;
  line-height: 30px;
`;

const Footer = ({ backgroundColor, backgroundImg }) => (
  <FooterContent backgroundColor={backgroundColor} backgroundImg={backgroundImg}>
    <FooterBottom>
      <FooterLogoContainer>
        <LogoLink to="/">
          <StyledWhiteLogo />
        </LogoLink>
      </FooterLogoContainer>
      <FooterLinks>
        <div>
          <FooterLinkHeader>
            Product
          </FooterLinkHeader>
          <FooterLinkInternal to="/product">
            Product Tours
          </FooterLinkInternal>
          <FooterLinkExternal href="https://console.percept.ai">
            Console Login
          </FooterLinkExternal>
        </div>
        <div>
          <FooterLinkHeader>
            Trust
          </FooterLinkHeader>
          <FooterLinkInternal to="/trust">
            Security &amp; Privacy
          </FooterLinkInternal>
          <FooterLinkInternal to="/gdpr">
            GDPR
          </FooterLinkInternal>
        </div>
        <div>
          <FooterLinkHeader>
            Company
          </FooterLinkHeader>
          <FooterLinkInternal to="/company">
            About Us
          </FooterLinkInternal>
          <FooterLinkInternal to="/careers">
            Careers
          </FooterLinkInternal>
        </div>
        <div>
          <FooterLinkHeader>
            Resources
          </FooterLinkHeader>
          <FooterLinkInternal to="/resources">
            Library
          </FooterLinkInternal>
          <FooterLinkInternal to="/terms-of-use">
            Terms of Use
          </FooterLinkInternal>
          <FooterLinkInternal to="/privacy-policy">
            Privacy Policy
          </FooterLinkInternal>
        </div>
        <div>
          <FooterLinkHeader>
            Contact Us
          </FooterLinkHeader>
          <FooterLinkText>
            <div>440 N Wolfe Rd,</div>
            <div>Sunnyvale CA, US</div>
          </FooterLinkText>
          <FooterLinkEmail>
            support@atlassian.com
          </FooterLinkEmail>
        </div>
      </FooterLinks>
    </FooterBottom>
  </FooterContent>
);

Footer.propTypes = {
  backgroundColor: PropTypes.string,
};

Footer.defaultProps = {
};

export default Footer;
