import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

export const StyledLink = styled(Link)`
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin: 0 15px;
  text-decoration: none;
  padding-bottom: 5px;
  color: ${props => props.whiten ? 'white' : props.demo ? '#798BF3' : '#55617C'};
  padding: 20px 10px 20px 10px;

  ${props =>
    props.circle &&
      css`
        padding: 15px 25px;
        border: 2px solid ${props.whiten ? '#ffffff60' : '#DBDDFF60'};
        border-radius: 24.5px;

        &:hover {
          color: white;
          border: 2px solid ${props.whiten ? '#FFFFFF80' : '#DBDDFF'};
        }
      `}

  &:hover {
    color: ${props => props.whiten ? '#f3f3f3'  : '#788DF1'};
  }
`;

export const StyledA = styled.a`
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;
  margin: 0 10px;
  padding-bottom: 5px;
  color: ${props => props.whiten ? 'white' : '#55617C'};
  padding: 20px 10px 20px 10px;

  &:hover {
    color: ${props => props.whiten ? '#f3f3f3'  : '#788DF1'};
  }
`;
