import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import WhiteLogo from './inline/WhiteLogo.svg';
import { StyledLink, StyledA } from './common';
import { CloseIcon } from '../Icons';

const StyledWhiteLogo = styled(WhiteLogo)`
  flex: 1;
  width: 100%;
  height: 100%;
`;

const MobileLogo = styled.div`
  width: 100px;
  height: 78px;
`;

const Content = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  z-index: 1001;
  height: 100vh;
  width: 100vw;
  color: white;
  background-image: linear-gradient(190deg, #9b7cfb 0%, #6c72fa 100%);
  overflow: auto;
`;

const Header = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 20px;

  img {
    flex: 0 0 auto;
  }
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  a {
    margin: 8px 0px;
  }
`;

const CloseHolder = styled.button`
  fill: #ffffff;
`;

const NavPage = ({ closeNav }) => {
  const activeStyle = {
    borderBottom: `1px solid white`,
  };

  return (
    <Content>
      <Header>
        <MobileLogo>
          <StyledWhiteLogo />
        </MobileLogo>
        <CloseHolder onClick={closeNav}>
          <CloseIcon />
        </CloseHolder>
      </Header>
      <Links>
        <StyledLink to='/' activeStyle={activeStyle} whiten={1}>
          Home
        </StyledLink>
        <StyledLink to='/product' activeStyle={activeStyle} whiten={1}>
          Product
        </StyledLink>
        <StyledLink to='/trust' activeStyle={activeStyle} whiten={1}>
          Trust
        </StyledLink>
        <StyledLink to='/company' activeStyle={activeStyle} whiten={1}>
          Company
        </StyledLink>
        <StyledLink to='/resources' activeStyle={activeStyle} whiten={1}>
          Resources
        </StyledLink>
        <StyledA href='https://console.percept.ai' whiten={1}>
          Login
        </StyledA>
      </Links>
    </Content>
  );
};

NavPage.propTypes = {
  closeNav: PropTypes.func.isRequired,
};

export default NavPage;
