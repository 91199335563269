import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { StyledLink, StyledA } from "./common";

const Links = styled.div`
  display: none;
  align-items: center;
  justify-content: space-between;
  flex: 1;

  @media only screen and (min-width: 920px) {
    display: flex;
  }
`;

const RegularLinks = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CornerLinks = styled.div`
  color: ${props => props.whiteCorner ? 'white' : '#55617C'} !important;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 0 0 350px;
`;

const NavBar = ({ whiteMiddle, whiteCorner }) => {
  const activeColor = '#7389F0';
  const activeStyle = whiteMiddle ? {
    color: '#FFFFFF',
    borderBottom: `1px solid #FFFFFF`,
  } : {
    color: activeColor,
    borderBottom: `1px solid ${activeColor}`,
  };

  return (
    <Links>
      <RegularLinks>
        <StyledLink
          to="/product"
          activeStyle={activeStyle}
          whiten={whiteMiddle ? 1 : 0}
        >
          Product
        </StyledLink>
        <StyledLink
          to="/trust"
          activeStyle={activeStyle}
          whiten={whiteMiddle ? 1 : 0}
        >
          Trust
        </StyledLink>
        <StyledLink
          to="/company"
          activeStyle={activeStyle}
          whiten={whiteMiddle ? 1 : 0}
        >
          Company
        </StyledLink>
        <StyledLink
          to="/resources"
          activeStyle={activeStyle}
          whiten={whiteMiddle ? 1 : 0}
        >
          Resources
        </StyledLink>
      </RegularLinks>
      <CornerLinks>
        <StyledA
          href="https://console.percept.ai"
          whiten={whiteCorner ? 1 : 0}
        >
          Login
        </StyledA>
      </CornerLinks>
    </Links>
  );
};

NavBar.propTypes = {
  whiteMiddle: PropTypes.bool,
  whiteCorner: PropTypes.bool,
};

export default NavBar;
