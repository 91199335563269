import React from 'react';
import styled from 'styled-components';

import AugmentIcon from './inline/AugmentIcon.svg';
import BenefitsIcon from './inline/BenefitsIcon.svg';
import GoodCompanyIcon from './inline/GoodCompanyIcon.svg';
import RightArrowIcon from './inline/RightArrowIcon.svg';
import ScrollDownIcon from './inline/ScrollDownIcon.svg';
import WorkFlowIcon from './inline/WorkFlowIcon.svg';

import DoubleRightArrowIcon from './inline/DoubleRightArrowIcon.svg';
import LockIcon from './inline/LockIcon.svg';
import ProfileCheckIcon from './inline/ProfileCheckIcon.svg';
import ProfileIcon from './inline/ProfileIcon.svg';
import ScrollIcon from './inline/ScrollIcon.svg';
import SendIcon from './inline/SendIcon.svg';
import TailoredIcon from './inline/TailoredIcon.svg';

import ChatBubbleIcon from './inline/ChatBubbleIcon.svg';
import CloudShieldIcon from './inline/CloudShieldIcon.svg';
import DataOwnershipIcon from './inline/DataOwnershipIcon.svg';
import GlassesIcon from './inline/GlassesIcon.svg';
import GlowingLockIcon from './inline/GlowingLockIcon.svg';
import HeartIcon from './inline/HeartIcon.svg';
import SecureUserIcon from './inline/SecureUserIcon.svg';
import ShieldIcon from './inline/ShieldIcon.svg';
import UserIcon from './inline/UserIcon.svg';
import DataKeyIcon from './inline/DataKeyIcon.svg';

import ScaleIcon from './inline/ScaleIcon.svg';
import ReduceCostsIcon from './inline/ReduceCostsIcon.svg';
import SignalIcon from './inline/SignalIcon.svg';

import SkinnyCheckIcon from './inline/SkinnyCheckIcon.svg';
import CheckIcon from './inline/CheckIcon.svg';
import MailIcon from './inline/MailIcon.svg';

import CalendarIcon from './inline/CalendarIcon.svg';
import ChatDotsIcon from './inline/ChatDotsIcon.svg';
import StarIcon from './inline/StarIcon.svg';
import PeopleIcon from './inline/PeopleIcon.svg';
import QuoteIcon from './inline/QuoteIcon.svg';
import KeyStatsIcon from './inline/KeyStatsIcon.svg';
import HourglassIcon from './inline/HourglassIcon.svg';
import LightbulbIcon from './inline/LightbulbIcon.svg';
import ColoredGroupIcon from './inline/ColoredGroupIcon.svg';

import HamburgerIcon from './inline/HamburgerIcon.svg';
import CloseIcon from './inline/CloseIcon.svg';

import PurpleCheckIcon from './inline/PurpleCheckIcon.svg';

import ProblemIcon from './inline/ProblemIcon.svg';
import SolutionIcon from './inline/SolutionIcon.svg';
import ResultsIcon from './inline/ResultsIcon.svg';
import QuoteIconBg from './inline/QuoteIconBg.svg';

const Wrapper = styled.div`
  background: #FFFFFF;
  box-shadow: 0 15px 40px 0 rgba(28,40,86,0.15);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 15px;
  width: 70px;
  height: 70px;
`;

const Triangle = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 110px;
  &::after{
    content: '';
    background-color: #6673EE;
    border-radius: 20px;
    width: 78px;
    height: 78px;
    margin: auto;
    opacity: 0.1;
    transform: rotate(45deg);    
    position: absolute;
    top: -999px;
    right: -999px;
    bottom: -999px;
    left: -999px;
  }
`;

function withShadow(WrappedIcon) {
  return function Icon(props) {
    return (
      <Wrapper>
        <WrappedIcon {...props} />
      </Wrapper>
    );
  };
}

function withTriangle(WrappedIcon) {
  return function Icon(props) {
    return (
      <Triangle>
        <WrappedIcon {...props} />
      </Triangle>
    );
  };
}

const ShadedAugmentIcon = withShadow(AugmentIcon);
const ShadedBenefitsIcon = withShadow(BenefitsIcon);
const ShadedGoodCompanyIcon = withShadow(GoodCompanyIcon);
const ShadedRightArrowIcon = withShadow(RightArrowIcon);
const ShadedScrollDownIcon = withShadow(ScrollDownIcon);
const ShadedWorkFlowIcon = withShadow(WorkFlowIcon);
const ShadedDoubleRightArrowIcon = withShadow(DoubleRightArrowIcon);
const ShadedLockIcon = withShadow(LockIcon);
const ShadedProfileCheckIcon = withShadow(ProfileCheckIcon);
const ShadedProfileIcon = withShadow(ProfileIcon);
const ShadedScrollIcon = withShadow(ScrollIcon);
const ShadedSendIcon = withShadow(SendIcon);
const ShadedTailoredIcon = withShadow(TailoredIcon);
const ShadedChatBubbleIcon = withShadow(ChatBubbleIcon);
const ShadedCloudShieldIcon = withShadow(CloudShieldIcon);
const ShadedDataOwnershipIcon = withShadow(DataOwnershipIcon);
const ShadedGlassesIcon = withShadow(GlassesIcon);
const ShadedGlowingLockIcon = withShadow(GlowingLockIcon);
const ShadedHeartIcon = withShadow(HeartIcon);
const ShadedSecureUserIcon = withShadow(SecureUserIcon);
const ShadedShieldIcon = withShadow(ShieldIcon);
const ShadedUserIcon = withShadow(UserIcon);
const ShadedDataKeyIcon = withShadow(DataKeyIcon);
const ShadedKeyStatsIcon = withShadow(KeyStatsIcon);
const ShadedStarIcon = withShadow(StarIcon);
const ShadedPeopleIcon = withShadow(PeopleIcon);
const ShadedColoredGroupIcon = withShadow(ColoredGroupIcon);

const TriangleProblemIcon = withTriangle(ProblemIcon);
const TriangleSolutionIcon = withTriangle(SolutionIcon);
const TriangleResultsIcon = withTriangle(ResultsIcon);

export {
  AugmentIcon,
  ShadedAugmentIcon,
  BenefitsIcon,
  ShadedBenefitsIcon,
  GoodCompanyIcon,
  ShadedGoodCompanyIcon,
  RightArrowIcon,
  ShadedRightArrowIcon,
  ScrollDownIcon,
  ShadedScrollDownIcon,
  WorkFlowIcon,
  ShadedWorkFlowIcon,
  DoubleRightArrowIcon,
  ShadedDoubleRightArrowIcon,
  LockIcon,
  ShadedLockIcon,
  ProfileCheckIcon,
  ShadedProfileCheckIcon,
  ProfileIcon,
  ShadedProfileIcon,
  ScrollIcon,
  ShadedScrollIcon,
  SendIcon,
  ShadedSendIcon,
  TailoredIcon,
  ShadedTailoredIcon,
  ChatBubbleIcon,
  ShadedChatBubbleIcon,
  CloudShieldIcon,
  ShadedCloudShieldIcon,
  DataOwnershipIcon,
  ShadedDataOwnershipIcon,
  GlassesIcon,
  ShadedGlassesIcon,
  GlowingLockIcon,
  ShadedGlowingLockIcon,
  HeartIcon,
  ShadedHeartIcon,
  SecureUserIcon,
  ShadedSecureUserIcon,
  ShieldIcon,
  ShadedShieldIcon,
  UserIcon,
  ShadedUserIcon,
  DataKeyIcon,
  ShadedDataKeyIcon,
  ScaleIcon,
  ReduceCostsIcon,
  SignalIcon,
  SkinnyCheckIcon,
  CheckIcon,
  MailIcon,
  CalendarIcon,
  ChatDotsIcon,
  StarIcon,
  ShadedStarIcon,
  PeopleIcon,
  ShadedPeopleIcon,
  QuoteIcon,
  KeyStatsIcon,
  ShadedKeyStatsIcon,
  HourglassIcon,
  LightbulbIcon,
  ColoredGroupIcon,
  ShadedColoredGroupIcon,
  HamburgerIcon,
  CloseIcon,
  PurpleCheckIcon,
  TriangleProblemIcon,
  TriangleSolutionIcon,
  TriangleResultsIcon,
  QuoteIconBg,
};
