/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import Header from './header/header';
import Footer from './footer/footer';

const LayoutContent = styled.div`
  overflow-x: hidden;
`;

const LayoutMain = styled.div`
  padding-top: 120px;
`;

const Layout = ({
  children,
  headerBackgrounds,
  navBackgrounds,
  whiteMobile,
  whiteLogo,
  whiteMiddle,
  whiteCorner,
  hideDemo,
  footerBackgroundColor,
  footerBackgroundImg,
}) => (
    <StaticQuery
      query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
      render={(data) => (
        <LayoutContent>
          <Header
            siteTitle={data.site.siteMetadata.title}
            navBackgrounds={navBackgrounds}
            headerBackgrounds={headerBackgrounds}
            whiteMobile={whiteMobile}
            whiteLogo={whiteLogo}
            whiteMiddle={whiteMiddle}
            whiteCorner={whiteCorner}
          />
          <LayoutMain>{children}</LayoutMain>
          <Footer hideDemo={hideDemo} backgroundColor={footerBackgroundColor} backgroundImg={footerBackgroundImg} />
        </LayoutContent>
      )}
    />
  );

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  headerBackgrounds: PropTypes.array,
  navBackgrounds: PropTypes.array,
  whiteMobile: PropTypes.bool,
  whiteLogo: PropTypes.bool,
  whiteMiddle: PropTypes.bool,
  whiteCorner: PropTypes.bool,
  hideDemo: PropTypes.bool,
  footerBackgroundColor: PropTypes.string,
  footerBackgroundImg: PropTypes.string,
};

export default Layout;
