import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { CloseIcon } from '../Icons';

const Center = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BannerContainer = styled.div`
  ${Center}
  width: 100%;
  background-color: #A5A9FF;
  color: #FFFFFF;
  height: 35px;
  font-size: 12px;
  display: ${props => props.show ? 'flex' : 'none'};
`;

const BannerText = styled.div`
  flex: 1;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  padding-left: 35px;
`;

const BannerLink = styled.a`
  text-decoration: none;
  color: #FFFFFF;
  font-weight: 700;
`;

const BannerCloseContainer = styled.div`
  ${Center}
  align-self: flex-end;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  background: rgba(0, 0, 0, 0.2);
  cursor: pointer;
`;

const StyledCloseIcon = styled(CloseIcon)`
  color: #FFFFFF;
  width: 15px;
  height: 15px;
`;

const Banner = () => {
  const [bannerOpen, setBannerOpen] = useState(true);
  return (
    <BannerContainer show={bannerOpen}>
      <BannerText>
        <BannerLink href="https://atlassian.com/blog/announcements/atlassian-acquires-percept">
          PERCEPT.AI HAS BEEN ACQUIRED BY ATLASSIAN
        </BannerLink>
      </BannerText>
      <BannerCloseContainer onClick={() => setBannerOpen(false)}>
        <StyledCloseIcon />
      </BannerCloseContainer>
    </BannerContainer>
  )
};

export default Banner;
