import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import RegularLogo from './inline/Logo.svg';
import WhiteLogo from './inline/WhiteLogo.svg';
import NavPage from './navpage';
import NavBar from './navbar';
import Banner from './banner';
import { HamburgerIcon } from '../Icons';

export const StyledWhiteLogo = styled(WhiteLogo)`
  flex: 1;
  width: 100%;
  height: 100%;
`;

export const StyledRegularLogo = styled(RegularLogo)`
  flex: 1;
  width: 100%;
  height: 100%;
`;

const Container = styled.header`
  position: relative;
`;

const HeaderWrapper = styled.nav`
  position: relative;
`;

const HeaderContent = styled.div`
  z-index: 1000;
  position: fixed;
  width: 100%;
  transition: background-color 200ms linear;

  ${(props) =>
    props.sticky &&
    css`
      background-color: white;
      border-bottom: 2px solid #dbddff60;
    `}
`;

const HeaderItems = styled.div`
  padding: 10px 20px;
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LogoLinkHolder = styled.div`
  display: flex;

  @media only screen and (min-width: 920px) {
    flex: 0 0 350px;
  }
`;

export const LogoLink = styled(Link)`
  width: 100px;
  height: 78px;
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MobileNav = styled.div`
  @media only screen and (min-width: 920px) {
    display: none;
  }
`;

const HamburgerHolder = styled.button`
  ${(props) =>
    props.whiten
      ? css`
          fill: #ffffff;
        `
      : css`
          fill: #55617c;
        `}
`;

const HeaderBackgroundContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
`;

const Header = ({
  navBackgrounds,
  headerBackgrounds,
  whiteMobile,
  whiteLogo,
  whiteMiddle,
  whiteCorner,
}) => {
  const [navOpen, setNav] = useState(false);
  const [stickyNav, setStickyNav] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', function() {
      if (window.scrollY > 90) {
        setStickyNav(true);
      } else {
        setStickyNav(false);
      }
    });
  });

  return (
    <Container>
      <HeaderWrapper>
        <HeaderContent sticky={stickyNav}>
          <Banner />
          <HeaderItems>
            <LogoLinkHolder>
              <LogoLink to='/'>
                {whiteLogo && !stickyNav ? (
                  <StyledWhiteLogo />
                ) : (
                  <StyledRegularLogo />
                )}
              </LogoLink>
            </LogoLinkHolder>
            <NavBar
              whiteLogo={whiteLogo && !stickyNav}
              whiteMiddle={whiteMiddle && !stickyNav}
              whiteCorner={whiteCorner && !stickyNav}
            />
            <MobileNav>
              <HamburgerHolder
                onClick={() => setNav(!navOpen)}
                whiten={whiteMobile && !stickyNav}
              >
                <HamburgerIcon />
              </HamburgerHolder>
            </MobileNav>
          </HeaderItems>
        </HeaderContent>
        <HeaderBackgroundContainer>
          {headerBackgrounds && headerBackgrounds.map((b) => b)}
        </HeaderBackgroundContainer>
      </HeaderWrapper>
      {navOpen && (
        <NavPage
          closeNav={() => {
            setNav(false);
          }}
        />
      )}
      {navBackgrounds && navBackgrounds.map((b) => b)}
    </Container>
  );
};

Header.propTypes = {
  navBackgrounds: PropTypes.array,
  headerBackgrounds: PropTypes.array,
  whiteMobile: PropTypes.bool,
  whiteLogo: PropTypes.bool,
  whiteMiddle: PropTypes.bool,
  whiteCorner: PropTypes.bool,
};

export default Header;
